import React from 'react';
import ProjectBox from './ProjectBox';
import Bubble from '../images/TindogImage.png';
import Auris2 from '../images/Auris2.jpg';
import Vrunner from '../images/Vrunner.png';
import Pixelquest from '../images/PixelQuest.png';
import runner from '../images/Runner.jpg';
import space from '../images/SpaceCargo.png';
import auris from '../images/Auris.jpg';
import Shipyard from '../images/ShipyardManager.png';
import Pumpaction from '../images/PumpAction.png';
import Heavydriver from '../images/HeavyDriver.png';
import Tinyfighters from '../images/TinyFighters.png';
import Sheepgonemad from '../images/SheepGoneMad.png';
import ColorDefender from '../images/ColorDefender.png';



const Projects = () => {
  return (
    <div>
      <h1 className='projectHeading'>Our <b>Portfolio</b></h1>
      <div className='project'>
        {/* <ProjectBox projectPhoto={Bubble} projectName="Bubble"/> */}
        {/* <ProjectBox projectPhoto={Bubble} projectName="Match3"/>
        <ProjectBox projectPhoto={Bubble} projectName="Block"/>
        <ProjectBox projectPhoto={Bubble} projectName="Sudoko"/> */}
        <ProjectBox projectPhoto={space} projectName="SpaceCargo"/>
        <ProjectBox projectPhoto={auris} projectName="Auris"/>
        <ProjectBox projectPhoto={Auris2} projectName="Auris2"/>
        <ProjectBox projectPhoto={Vrunner} projectName="Vrunner"/>
        <ProjectBox projectPhoto={Shipyard} projectName="Shipyard"/>
        <ProjectBox projectPhoto={Pumpaction} projectName="Pumpaction"/>
        <ProjectBox projectPhoto={Heavydriver} projectName="Heavydriver"/>
        <ProjectBox projectPhoto={Pixelquest} projectName="Pixelquest"/>
        <ProjectBox projectPhoto={Tinyfighters} projectName="Tiny"/>
        <ProjectBox projectPhoto={runner} projectName="Runner"/>
        <ProjectBox projectPhoto={Sheepgonemad} projectName="Sheep"/>
        <ProjectBox projectPhoto={ColorDefender} projectName="Color"/>
      </div>

    </div>
  )
}

export default Projects