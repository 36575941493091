import React from 'react';
import Skills from './Skills.js';
import Tilt from 'react-parallax-tilt';
import Lottie from "lottie-react"
import Coder from '../LottieFiles/coder.json';
import photonicon from '../images/photonicon.jpg';

const About = () => {
  return (
    <>
      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading' >Get to <b>know</b> us!</h1>
          <p>
          Welcome to Freezerz Studio, where innovation meets digital excellence! As a cutting-edge software development studio, we specialize in crafting exceptional websites and immersive games that captivate audiences and elevate user experiences. In the dynamic realm of game development, we leverage the latest technologies, including Unity3D, Photon Engine, Firebase, and PlayFab, to create engaging and visually stunning virtual worlds. <br/><br/>

          In the realm of website development, we harness the power of Node.js, React.js, Tailwind CSS, Vue.js, and Express.js to build robust, responsive, and aesthetically pleasing websites that seamlessly blend form and function. Our commitment to staying at the forefront of technology ensures that our clients receive state-of-the-art solutions that not only meet but exceed their expectations. <br/> <br/> 

          At Freezerz Studio, we are driven by a passion for pushing the boundaries of what is possible in the digital landscape. Whether you're envisioning a dynamic website that leaves a lasting impression or an immersive game that takes users on an unforgettable journey, our team of skilled developers is dedicated to turning your ideas into reality. Join us on a journey of innovation and excellence with Freezerz Studio – where every line of code is a step towards perfection.
          
          </p>
        </div>

        <div>
          <Tilt>
              <Lottie 
              className="illustration" 
              animationData={Coder} 
              loop={true} 
            />
          </Tilt>
        </div>

      </div>
      
      <h1 className='SkillsHeading'>Professional Skillset</h1>
      <div className='skills'>
        
        <Skills skill='Unity' />
        <Skills skill='Firebase' />
        {/* <div className='SkillBox'>
          <img style={{ width: '50%', height: '75%' }} src={photonicon} alt="" />
        </div> */}
        <Skills skill='Csharp' />
        <Skills skill='Node' />
        <Skills skill='React' />
        <Skills skill='Express' />
        <Skills skill='MongoDb' />
        <Skills skill='Javascript' />
        <Skills skill='Vue' />
        <Skills skill='Vercel' />
        <Skills skill='Bootstrap'/>
        <Skills skill='Tailwind'/>
        <Skills skill='Github' />
        
      </div>
    </>
  )
}

export default About