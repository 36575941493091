// import styled from "styled-components";

// import { BsDownload } from "react-icons/bs";
// import pdf from "../Resume.pdf";
// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


// const Resume = () => {
//   const[wid, setwid]=useState(window.innerWidth);

//   const handleResize=()=>{
//     setwid(window.innerWidth);
//   }

//   window.addEventListener("load", handleResize);
//   window.addEventListener("resize", handleResize);

//   return (
//     <div className='ResumePage'>
//       <Document file={pdf} className="resumeview">
//           <Page pageNumber= {1} scale={wid<700 ? ( wid>475? 0.7: 0.5): 1}/>
//       </Document>

//       <a href={pdf} target='_blank' download="Devansh's Resume">
//         <button className='downloadCV' type='button'>
//           <h3><BsDownload/>&nbsp; Download CV</h3>
//         </button>
//       </a>

//     </div>
//   )
// }

// export default Resume
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";

// npm i @emailjs/browser

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
          "service_2slcvyc",
          "template_vsf9nxp",
          form.current,
          "cQqfc3TpxotwYHDvC"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (


    <><div>
      
    </div>
    <StyledContactForm className="conatctform">
        <div>

          <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
          </form>
        </div>
      </StyledContactForm></>
  );
};

export default Contact;

// Styles
const StyledContactForm = styled.div`
  

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
     
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    textarea {
      max-width: 100%;
      
      width: 500px;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      width: 515px;
      margin-top: 2.5rem;
      cursor: pointer;
      background: rgb(249, 105, 14);
      color: white;
      border: none;
    }
  }
`;