import React from 'react';
import { RiYoutubeLine } from "react-icons/ri";
import { GrGamepad } from "react-icons/gr";


const  ProjectBox = ({projectPhoto, projectName}) => {
  const desc = {
    // TindogDesc : "This website is a landing page of Tinder but for dogs. It is a responsive website which was made to understand Bootstrap. I also learned how to host my project on Github and then how to deploy that project using Github pages.",
    // TindogGithub : "https://github.com/DevanshSahni/tindog",
    // TindogWebsite : "https://devanshsahni.github.io/tindog/",

    // RogFreeDesc : "A website that shows you over seven specialized yoga postures for specific diseases or health problems. This was a group project made in a team of two for a 36-hour-long online hackathon named Hackodisha 2.0.",
    // RogFreeGithub : "https://github.com/DevanshSahni/Rog-Free",
    // RogFreeWebsite : "https://devanshsahni.github.io/Rog-Free/",

    // NewsletterDesc:"A newsletter signup site made using Mailchimp API where the signups can be monitored from the MailChimp account. This project was made to understand API integration, environment variables and vercel deployment.",
    // NewsletterGithub:"",
    // NewsletterWebsite:"https://newsletter-signup-teal.vercel.app/",
    
    // WigglesDesc:"An innovative pet management web app enabling pet parents to create unique pet IDs, securely store and share vaccination records, and generate QR codes for pet profiles, enhancing safety.",
    // WigglesGithub:"https://github.com/DevanshSahni/Wiggles",
    // WigglesWebsite:"https://wiggles.vercel.app/",
    
    //Space Cargo//
    SpaceCargoProjectname: "Space Cargo",
    SpaceCargoDesc : "In Cargo The Mini Game, pilot a futuristic space freighter through perilous cosmic environments. Collect power-ups: Discover various power-ups scattered throughout the levels, granting you temporary boosts in firepower, defense, or agility.",
    SpaceCargoYoutube:"https://www.youtube.com/watch?v=daEyVR_HQuA",
    SpaceCargoWebsite:"https://cargoserie.com/cargo/cargo-le-jeu/",

    //Auris The Game//
    AurisProjectname: "Auris The Game",
    AurisDesc : "AURIS is JACK RABBIT'S Journey into the Light. He has escaped from “Domestic Violence” and has entered into the enchanted forest of the unknown. He needs your help to venture through it and keep going on his adventure into the light.",
    AurisYoutube:"https://www.youtube.com/watch?v=YT2koq79oAo",
    AurisWebsite:"https://play.google.com/store/apps/details?id=com.Auris.AurisTheGame",

    //Auris The Game 2//
    Auris2Projectname: "Auris The Game 2",
    Auris2Desc : "Full of rage against the machine Jack Rabbit is willing to fight the evil empire. JACK RABBIT comes full of wisdom from THE SACRED LIAR but also full of rage against the machine as he now knows how much THEY LIE just to keep us blind away from truth.",
    Auris2Youtube:"",
    Auris2Website:"https://www.gameloop.com/game/casual/auris-the-game-2-on-pc",

    //Vrunner//
    VrunnerProjectname: "Endless Vrunner",
    VrunnerDesc : "Embark on a stylish adventure in this unique endless runner game. Dash through vibrant environments, avoiding dirt hurdles and slithering snakes to keep your shirts pristine. Compete globally on the leaderboard, unlock achievements. It's a fast-paced, chic adventure that blends fashion and fun.",
    VrunnerYoutube:"",
    VrunnerWebsite:"Will add soon",

    //ShipYard Manager//
    ShipyardProjectname: "ShipYard Manager",
    ShipyardDesc : "Take control over ships, your goal is to safely dock as much ships as possible. Become the best coordinator of all times. Beat you own high score, be BETTER by every try! Upgrade your dock → Gain more space for ships → Earn more coins Be the best Shipyard Manager! Take over traffic",
    ShipyardYoutube:"https://www.youtube.com/watch?v=b5ybRfleOIE",
    ShipyardrWebsite:"Will add soon",

    //Pump Action//
    PumpactionProjectname: "Pump Action",
    PumpactionDesc : "Prepare yourself for an adrenaline-charged, heart-pounding battle royale third-person shooter sensation! Play fast-paced and timed multiplayer and multi-team showdowns. Captivating maps, from gritty industrial arenas to breathtaking grasslands.",
    PumpactionYoutube:"https://www.youtube.com/watch?v=yigYxdhUYTE",
    PumpactionWebsite:"",

    //Heavy Driver//
    HeavydriverProjectname: "Heavy Driver",
    HeavydriverDesc : "It is a 3rd person vehicle-combat game. This game has 3 different maps. The player can play solo or with friends (Squad vs Squad). Player can also add friends. In the lobby or during game, players can also be able to chat with each other. There are about 5 different types of vehicles. They can also buy new vehicles using coins.",
    HeavydriverYoutube:"https://www.youtube.com/watch?v=KZPT-pg1z2w",
    HeavydriverWebsite:"https://kashirao.itch.io/heavy-driver ",

    //Pixel Quest//
    PixelquestProjectname: "Pixel Quest",
    PixelquestDesc : "Embark on the ultimate pixelated adventure in 'Pixel Quest: Dynamic Encounters,'. Choose wisely from a diverse cast, each tailored for specific terrains across 5 unique maps – snowy expanses, mysterious caves, lush jungles, towering mountains, and scorching deserts. Devising a unique strategy for each level by selecting characters with powers perfectly matched to the situation.",
    PixelquestYoutube:"https://www.youtube.com/watch?v=2Igb3k3htcc",
    PixelquestWebsite:"",    

    //Tiny Fighters//
    TinyProjectname: "Tiny Fighters",
    TinyDesc : "Tiny Fighters is an action-packed, miniature combat game where players control unique pint-sized warriors in intense one-on-one battles within dynamic arenas. Choose from a diverse roster of Tiny Fighters, each with distinct abilities, and engage in strategic, fast-paced combat. Challenge friends in multiplayer mode, climb the ranks, and prove your skills in this adrenaline-fueled showdown of tiny proportions.",
    TinyYoutube:"https://www.youtube.com/watch?v=LVM0fXL4a4s",
    TinyWebsite:"",  

    //Bubble Shooter Candy//
    BubbleProjectname: "Bubble Shooter Candy",
    BubbleDesc : "Bubble Shooter Candy, you'll have to pop your way through hundreds of levels, using your skills and strategy to clear the board and rescue the trapped baby animals. With a variety of different candy bubbles to choose from, and power-ups to help you along the way, Bubble Shooter Candy is sure to keep you entertained for hours on end.  ",
    BubbleYoutube:"",
    BubbleWebsite:"https://play.google.com/store/apps/details?id=com.GameCrypto.NFTBubbleShooter",  

    //Match 3 Champion//
    Match3Projectname: "Match 3 Champion",
    Match3Desc : "Welcome to Match 3 Champion, a captivating puzzle game that will immerse you in a world of delightful challenges. Engage in a visually enchanting experience as you swap, match, and crush vibrant gems and jewels in this classic match-three adventure. With hundreds of levels to conquer, each more enticing than the last, you'll find yourself addicted to the intuitive gameplay and mesmerizing animations.",
    Match3Youtube:"",
    Match3Website:"https://play.google.com/store/apps/details?id=com.GameCrypto.NFTMatch3",  

    //Block Champion//
    BlockProjectname: "Block Champion",
    BlockDesc : "Welcome to the world of Block 3 Champion, a thrilling puzzle game that transforms the classic match-three concept into a captivating block-matching adventure. Immerse yourself in a vibrant realm filled with colorful blocks and engaging challenges. Swap, match, and crush blocks to conquer a myriad of levels, each offering unique puzzles and strategic opportunities. ",
    BlockYoutube:"",
    BlockWebsite:"https://play.google.com/store/apps/details?id=com.GameCrypto.NFTBlocks",  

    //Sudoko Champion//
    SudokoProjectname: "Sudoko Champion",
    SudokoDesc : "Welcome to Sudoku Champion, the ultimate destination for puzzle enthusiasts seeking a challenging and rewarding experience. Immerse yourself in the timeless world of Sudoku, where numbers align to create a symphony of logic and strategy. With varying difficulty levels, from novice to expert, Sudoku Champion caters to players of all skill levels. Enjoy an intuitive interface, elegant design, and a vast array of puzzles that will put your logical reasoning to the test.",
    SudokoYoutube:"",
    SudokoWebsite:"https://play.google.com/store/apps/details?id=com.GameCrypto.NFTSudoku",   
   
    //Runner Champion//
    RunnerProjectname: "Runner Champion",
    RunnerDesc : "Embark on an endless adventure in Runner Champion, a heart-pounding endless runner set in the heart of a dense and mysterious jungle. As the player, navigate through an ever-changing landscape, dodging obstacles, and engaging in epic battles with alien invaders. Armed with the formidable power of fire, unleash scorching attacks to overcome extraterrestrial foes that lurk in the shadows. How far can you run, how many enemies can you defeat, and will you emerge as the true Runner Champion?",
    RunnerYoutube:"",
    RunnerWebsite:"https://play.google.com/store/apps/details?id=com.wade.chibiendlessrunner",   

    //Sheep Gone Mad//
    SheepProjectname: "Sheep's Gone Mad",
    SheepDesc : "Sheep's Gone Mad is a fast-paced action game where you, a brave sheep, defend against waves of cunning wolves and foxes with a powerful machine gun. Strategically place various machine guns around the fence using earned coins to protect your flock. Face increasingly challenging foes, collect coins for upgrades, and unleash a barrage of firepower to keep the invaders at bay. Can you lead your sheep to safety and become the ultimate defender in this woolly war?",
    SheepYoutube:"https://www.youtube.com/watch?v=72O7fUWFkVc",
    SheepWebsite:"",

    //Color Defender//
    ColorProjectname: "Color Defender",
    ColorDesc : "Color Defender is a vibrant tower defense game where you must strategically place the right color in each row to repel oncoming colorful enemies. Match hues with precision to defend your tower, and as you conquer each wave, you inch closer to becoming the ultimate defender. Quick thinking and color coordination are your weapons in this visually dynamic challenge. Can you withstand the relentless assault and emerge victorious in the world of Color Defender?",
    ColorYoutube:"https://www.youtube.com/watch?v=iI2WAYiJuj0",
    ColorWebsite:"",    

  }

  let show ='';
  let show1 ='';
  if(desc[projectName + 'Youtube']===""){
    show="none";
  }
  if(desc[projectName + 'Website']===""){
    show1="none";
  }
    
  return (
    <div className='projectBox'> 
        <img className='projectPhoto' src={projectPhoto} alt="Project display" /> 
        <div>
            <br />
            <h3>{desc[projectName + 'Projectname']}</h3>
            <br />
            <p className='projectdesc'>
            {desc[projectName + 'Desc']}
            </p>
            
            <br />

            <a style={{display:show}} href={desc[projectName + 'Youtube']} target='_blank'>
              <button className='projectbtn'><RiYoutubeLine/> Demo</button>
            </a>

            <a style={{display:show1}} href={desc[projectName + 'Website']} target='_blank'>
              <button className='projectbtn'><GrGamepad /> Play </button>
            </a>
        </div>
    </div>
  )
}

export default  ProjectBox