import React from 'react';
import Lottie from "lottie-react";
import SpaceBoy from "../LottieFiles/SpaceBoy.json";
import Typed from "./Typed.js";
import Tilt from 'react-parallax-tilt';
import Avatar from '../images/Avatar.png';
import { CiCoffeeCup } from "react-icons/ci";
import anim1 from "../LottieFiles/1 anim.json";
import Gamepad from "../LottieFiles/GamePad.json";

const Home = () => {
  return (
    <div >
      <div className='HomePage'>

        <div className='HomeText'>
          <h1>Hi There!</h1>
          <h1>We are <b>Freezerz Studio </b></h1>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, marginRight: -40 }}>
            <h2 style={{ fontSize: 28, marginRight: 15, paddingTop: 3 }}>
              <b>We Do</b>
            </h2>
            <Typed />
          </div>
        </div>
        <Tilt>
          <Lottie
            className="illustration"
            animationData={anim1}
            loop={true}
          />
        </Tilt>

      </div>

      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading'>Brief <b>introduction</b></h1>
          <p>
          Welcome to <b>Freezerz Studio</b> – your go-to for premium software development.<br/>
          Specializing in both websites and games, we employ cutting-edge technologies. <br/>
          For games, we use <b>Unity3D</b>, <b>Photon Engine</b>, <b>Firebase</b>, and <b>PlayFab</b>. 
          In website development, our toolkit includes <b>Node.js</b>, <b>React.js</b>, <b>Tailwind CSS</b>, <b>Vue.js</b>, and <b>Express.js</b>. 
          Elevate your digital presence with Freezerz Studio, where innovation meets tailored excellence.
          </p>
        </div>
        <Tilt>
          <Lottie
            className="illustration1"
            animationData={Gamepad}
            loop={true}
          />
          {/* <img className='Avatar' src={Avatar} alt="" /> */}
        </Tilt>
      </div>
    </div>
  )
}

export default Home